import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import styles from './index.module.css';
import Logo from '../components/logo';

const HowToInstallPage = () => {
  const downloadLink = 'https://bit.ly/3ad1b8f';

  return (
    <Layout>
      <SEO title="How to Install"/>

      {/* Logo */}
      <Logo/>

      {/* Upgrade */}
      <div
        className={'p-20 text-gray-300 w-full bg-gray-800 lg:p-10 rounded-lg'}
        id={'how-to-install-stocksolo'}>
        <h2>
          How to upgrade
        </h2>
        <div>
          If you installed StockSolo using Adobe Exchange, you don't need to do anything, the extension will update automatically.
          <br/>
          <br/>
          <b>If you installed the extension manually:</b>

          <ol className={'list-disc py-2 mb-0'}>
            <li>Download the Anastasiy’s Extension Manager from this link <a
              href={'http://install.anastasiy.com'}>http://install.anastasiy.com</a> and
              install it.
            </li>
            <li>Download the StockSolo extension from this link: <a
              href={downloadLink} className={'text-bold'}>Download</a>.</li>
            <li>Open the Anastasiy’s Extension Manager.</li>
            <li>Drag and Drop the <a href={downloadLink}>“StockSolo.zxp”</a> inside Anastasiy’s Extension
              Manager.
            </li>
            <li>Follow the installation steps.</li>
            <li>Restart the Adobe CC applications that are open.</li>
          </ol>

          <div className="mt-5 mb-10">
            <a href={downloadLink} className={'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 ' +
    'px-4 mt-5 lg:mt-0 lg:ml-2 w-full rounded focus:outline-none ' +
    'focus:shadow-outline lg:flex-2 text-center'}>
            Download "StockSolo.zxp"
          </a>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HowToInstallPage;
